<div class="wallet-transfer">
  <div class="wallet-wrap">
    <div class="transfer-header">
      <div class="title">
        <span>{{ 'wallet_trans' | translate }}</span>
        <i class="icon-close-simple mutual-opacity onact96-not" (click)="close()"></i>
      </div>
      <p class="tips">{{ 'free_trans' | translate }}</p>
    </div>
    <div class="main-content" [appLoading]="loading" [class.loading]="loading">
      <div class="transfer-content">
        <div class="select-group">
          <!-- 调换按钮 -->
          <div class="swap">
            <div>
              <span class="swap-btn mutual-opacity onact96" [class.disabled]="blockControl" (click)="swapWalletValue()">
                <img src="assets/images/wallet/wallet-exchange-dark.svg" />
              </span>
            </div>
          </div>
          <!-- 钱包选择 -->
          <div class="accounts">
            <app-customize-select
              [label]="'from' | translate"
              width="100%"
              [inputTemplate]="accountsInputTemplate"
              [ignoreDuplicateSelectEvent]="true"
              [options]="walletList"
              [textKey]="'walletName'"
              [valueKey]="'category'"
              [(value)]="selectedFromValue"
              [disabled]="blockControl"
              (onSelect)="onFromValueSelect()"
            >
            </app-customize-select>
            <app-customize-select
              [label]="'to' | translate"
              width="100%"
              [inputTemplate]="accountsInputTemplate"
              [ignoreDuplicateSelectEvent]="true"
              [options]="walletList"
              [textKey]="'walletName'"
              [valueKey]="'category'"
              [(value)]="selectedToValue"
              [disabled]="blockControl"
              (onSelect)="onToValueSelect()"
            >
            </app-customize-select>
          </div>
        </div>
        <ng-container *ngIf="!noMainWallet">
          <!-- 币种选择 -->
          <div class="currency-select">
            <app-customize-select
              [label]="'curr' | translate"
              width="100%"
              [inputTemplate]="currencySelectInputTemplate"
              [disabled]="blockControl"
            >
            </app-customize-select>
          </div>
          <!-- 金额输入 -->
          <div class="amount-input">
            <app-customize-input
              [label]="'number' | translate"
              size="large"
              type="number"
              [(value)]="inputAmountValue"
              placeholder="{{ 'mini_am_pla' | translate }} {{ minAmount }}"
              [disabled]="blockControl"
              [format]="amountValueFormat"
              (onChange)="amountValueChange($event)"
            >
              <span info class="amount-info">
                {{ 'ava_balance' | translate }}
                {{ maxAmount | currencyValue : selectedCurrencyValue : undefined : undefined : 2 }}
                {{ selectedCurrencyValue }}
              </span>
              <span right-content class="amount-right">
                {{ selectedCurrencyValue }}
                <span class="mutual-opacity onact96-not" (click)="setMaxValueAmount()">{{ 'max' | translate }}</span>
              </span>
            </app-customize-input>
          </div>
        </ng-container>
        <!-- 综合错误信息 -->
        <div class="msg-box">
          <!-- 主账户没激活（没钱） -->
          <p *ngIf="noMainWallet; else otherMsg">{{ 'top_to_curr' | translate }}</p>
          <ng-template #otherMsg>
            <!-- 转账激活 -->
            <p *ngIf="toWallet?.isFirst">{{ 'trans_money' | translate }}</p>
            <!-- 选择的游戏钱包币种没钱可转出 -->
            <p *ngIf="selectedFromValue !== 'Main' && maxAmount <= 0">{{ 'tips_balance' | translate }}</p>
            <!-- 金额错误 -->
            <p *ngIf="errorAmountTip">{{ errorAmountTip }}</p>
          </ng-template>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="transfer-foot">
        <customize-button
          *ngIf="!moneyLess"
          width="100%"
          type="primary"
          size="large"
          [disabled]="!canSubmit"
          [loading]="submitLoading"
          (onClick)="submit()"
        >
          {{ 'confirm_trans' | translate }}
        </customize-button>
        <customize-button *ngIf="moneyLess" width="100%" type="primary" size="large" (onClick)="toTopUp()">
          {{ 'recharge' | translate }}
        </customize-button>
      </div>
    </div>
  </div>
</div>

<!-- 钱包选择框内部 -->
<ng-template #accountsInputTemplate let-options="options" let-value="value">
  <div class="input-text">
    <i class="{{ value === 'Main' ? 'icon-tinybag' : 'icon-wallet-coin' }}"></i>
    {{ options | filterByKeyValue : 'category' : value : 'walletName' }}
  </div>
</ng-template>

<!-- 币种选择框内部 -->
<ng-template #currencySelectInputTemplate>
  <div class="input-text onact96" (click)="$event.stopPropagation(); openCurrencySelectPop()">
    <ng-container *ngIf="!currencyLoading">
      <img [src]="selectedCurrencyValue | currencyIcon" />
      <span>{{ selectedCurrencyValue }}</span>
      <em>{{ allCurrencies | filterByKeyValue : 'currency' : selectedCurrencyValue : 'name' }}</em>
    </ng-container>
    <i *ngIf="currencyLoading" class="animation-icon-loading-dot"><i></i><i></i><i></i></i>
  </div>
</ng-template>
